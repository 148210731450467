<template>
<div id="page" class="w-full flex flex-col overflow-auto plano">
  <div class="md:max-w-screen-2xl">
  <div v-if="planoId === 'giftCard'" class="w-full h-auto md:h-auto flex flex-row justify-start items-start font-rouben border-white text-white px-8 md:px-20 mt-20 mb-20 pt-40" id="drp-booking" :data-gift-card="true"></div>
    <div v-else class="w-full h-auto flex flex-row justify-start items-start font-rouben border-white text-white px-8 md:px-20 mt-20 mb-20 pt-40" id="drp-booking" :data-course-id=planoId></div>
  </div>
  <div class="w-full md:flex md:flex-col md:items-center">
    <footerComponent />
  </div>
</div>
</template>

<script>
// Components
import footerComponent from '../components/footer.vue';

export default {
  name: 'book',
  components: { footerComponent },
  data() {
    return {
      planoId: '',
      giftCard: false,
    };
  },

  beforeMount() {
    this.planoId = this.$route.params.slug;
  },

  mounted() {
    document.title = 'Kurs buchen';
    window.scrollTo({ top: 0, left: 0 });
    const drPlanoScript = document.createElement('script');
    drPlanoScript.setAttribute('id', 'drp-script');
    drPlanoScript.setAttribute('src', 'https://www.dr-plano.com/de/static/booking-plugin/code.js');
    drPlanoScript.setAttribute('data-backend-url', 'https://backend.dr-plano.com');
    drPlanoScript.setAttribute('data-id', '198750691');
    drPlanoScript.setAttribute('data-frontend-url', 'https://www.dr-plano.com/de');
    document.head.appendChild(drPlanoScript);
  },
};
</script>

<style>
.drp-closable-card {
  color: #09151B !important;
}
div.plano h2 {
  @apply font-extrabold text-6xl
}
div.plano input {
  color: #09151B;
}
div.plano select {
  color: #09151B;
}
div.plano textarea {
  color: #09151B;
}
</style>
